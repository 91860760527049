import * as React from "react";
import "./NavComponentStyles.css";
import SSILOGO from "../../assets/ssi-logo-landscape.svg";
import { Link } from "react-scroll";

export default function NavComponent(props) {
  return (
    <nav className="navigation">
      <div>
        <img className="ssi-logo" src={SSILOGO} />
      </div>

      <div className="navigation-menu">
        <div className="navContainer">
          <p>
            <Link activeClass="active" to="home" spy={true} smooth={true}>
              Home
            </Link>
          </p>
          <p>
            <Link to="about" spy={true} smooth={true}>
              About
            </Link>
          </p>
          <p>
            <Link to="contact" spy={true} smooth={true}>
              Contact
            </Link>
          </p>
        </div>
      </div>
    </nav>
  );
}
