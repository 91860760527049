import React from "react";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import Capture from "../../assets/captureaudit.svg";
import "./ProjectsStyles.css";

function Projects() {
  return (
    <div className="aboutMain">
      <h1 className="sectionHeaders" style={{ color: "#8B0000" }}>
        Projects
      </h1>
      <Grid
        container
        sx={{
          paddingX: "10%",
        }}
        display="flex"
        alignItems="center"
        // jusifyContent="space-evenly"
        flexDirection={{
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            textAlign: "center",
          }}
          //   display="flex"
          //   direction="column"
          //   alignItems="center"
          //   justifyContent="center"
          className="projectImgGrid"
        >
          <img src={Capture} className="projectImg"></img>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            textAlign: "center",
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <h1 style={{ color: "#8B0000", fontSize: "50px" }}>
            SSI <span style={{ color: "black" }}>CAPTURE</span>
          </h1>
          <motion.h3
            initial={{ opacity: 0 }}
            transition={{ duration: 2 }}
            whileInView={{ opacity: 1 }}
            style={{
              width: "350px",
              textAlign: "center",
            }}
          >
            Your company creates a template. The template is tailored to the
            companies capturing fields. Create organisations for the template.
            Assign team members to the organisations. Capture data for that
            template.
          </motion.h3>
        </Grid>
      </Grid>
    </div>
  );
}

export default Projects;
