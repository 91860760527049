import React from "react";
import { Grid } from "@mui/material";
import octoco from "../../assets/octoco.png";
import octoco2 from "../../assets/octoco2.png";
import averly from "../../assets/averly.png";
import mfw from "../../assets/mfw.svg";
import "./OurClientsStyles.css";

const ClientCards = (props) => {
  return (
    <div className="clientCards">
      <div className="imageWrapper">
        <img className="image" src={props.logo} />
      </div>
    </div>
  );
};

function OurClients() {
  return (
    <div className="ourClients">
      <h1 className="sectionHeaders" style={{ color: "#8B0000" }}>
        Our Clients
      </h1>
      <Grid
        container
        // sx={{
        //   textAlign: "center",
        // }}
        spacing={2}
        className="grid"
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        flexDirection={{
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}

        // justifyContent="space-evenly"
      >
        <Grid item>
          <ClientCards title="Octoco" logo={octoco2} />
        </Grid>
        <Grid item>
          <ClientCards title="Averly" logo={averly} />
        </Grid>
        <Grid item>
          <ClientCards title="My Farm Web" logo={mfw} />
        </Grid>
      </Grid>
    </div>
  );
}

export default OurClients;
