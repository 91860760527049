import "./App.css";
import NavComponent from "./Components/NavComponent/NavComponent";
import HeroComponent from "./Components/HeroComponent/HeroComponent";
import AboutComponent from "./Components/About/AboutComponent";
import ProjectsComponent from "./Components/Projects/ProjectsComponent";
import OurClients from "./Components/OurClients/OurClients.js";
import ContactUs from "./Components/ContactUs/ContactUs";
import Projects from "./Components/OurProjects/Projects";

function App() {
  return (
    <div>
      <NavComponent />
      <HeroComponent />
      <AboutComponent />
      <Projects />
      {/* <ProjectsComponent /> */}
      <OurClients />
      <ContactUs />
    </div>
  );
}

export default App;
