import React from "react";
import "./ContactUsStyles.css";
import { Grid } from "@mui/material";

export default function ContactUs() {
  return (
    <div className="ContactUs" id="contact">
      <h1 className="sectionHeaders" style={{ color: "#8B0000" }}>
        Contact us
      </h1>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        {/* <Grid item>
          <p>phone</p>
        </Grid> */}
        <Grid item>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            email: info@ssi.engineering
          </p>
        </Grid>
        {/* <Grid item>
          <p>address</p>
        </Grid> */}
      </Grid>
    </div>
  );
}
